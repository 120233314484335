import { useUser } from 'api/users/useUser';
import { AcceptTerms } from 'components/AcceptTerms';
import { BigfootButton } from 'components/buttons/BigfootButton';
import { LargeButton } from 'components/buttons/LargeButton';
import { VippsButton } from 'components/buttons/VippsButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services/authService';
import { cn } from 'utils/className';
import { setAccessToken, setRefreshToken } from 'utils/token-storage';
import * as Yup from 'yup';

export default function Login() {
  const { data: user, mutate: refreshUser, loggedOut } = useUser(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const [showUsernamePassword, setShowUsernamePassword] = useState(false);

  useEffect(() => {
    if (user && !loggedOut) {
      navigate('/');
    }
  }, [user, loggedOut, navigate]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    password: Yup.string().required('Skriv inn passord'),
  });

  interface FormValues {
    email: string;
    password: string;
  }

  async function onSubmit({ email, password }: FormValues, { setSubmitting }: any) {
    setSubmitting(true);
    authService
      .login(email, password)
      .then((res) => {
        navigate('/');
        setRefreshToken(res.refreshToken);
        setAccessToken(res.accessToken);
        refreshUser();
        setSubmitting(false);
      })
      .catch((error) => {
        toast.error({ description: 'Feil brukernavn eller passord' });
      });
  }

  return (
    <div className="flex h-screen items-center justify-center" style={{ backgroundColor: theme.background.accent }}>
      <div className="mb-8 flex flex-col items-center space-y-6">
        <h1 className="bigheading text-center" style={{ color: theme.label.primary }}>
          Utleggsappen
        </h1>
        <div className={cn(showUsernamePassword ? 'hidden' : 'flex flex-col space-y-3')}>
          <VippsButton href={process.env.REACT_APP_VIPPS_LOGIN_URI!} />
          <BigfootButton backgroundColor="light" defaultStyle="indigo" href="/registrer">
            Ny bedrift?
          </BigfootButton>
        </div>

        <div className={cn(!showUsernamePassword && 'hidden', 'rounded-large bg-white p-5')}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            validateOnChange={false}>
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
              return (
                <Form className="space-y-5">
                  <Input.Text
                    label="E-post"
                    width="lg"
                    value={values.email}
                    error={touched.email ? errors.email : undefined}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                  <Input.Text
                    label="Passord"
                    password
                    value={values.password}
                    error={touched.password ? errors.password : undefined}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />
                  <div className="mt-6 flex h-12 w-full justify-center">
                    <LargeButton
                      backgroundColor="light"
                      defaultStyle="indigo"
                      disabled={false}
                      loading={isSubmitting}
                      type="submit">
                      Logg inn
                    </LargeButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div
          className={cn('caption clickable text-center underline', !showUsernamePassword && 'hidden')}
          onClick={() => setShowUsernamePassword(false)}>
          Logg inn med Vipps
        </div>

        <div
          className={cn('caption clickable text-center underline', showUsernamePassword && 'hidden')}
          onClick={() => setShowUsernamePassword(true)}>
          Logg inn med e-post
        </div>

        <AcceptTerms />

        {process.env.NODE_ENV === 'development' && (
          <div className="flex flex-col justify-center space-y-3 rounded-lg border-2 border-purple-600 p-8 px-24 text-center align-middle">
            <div>
              <p>Utviklermodus</p>
            </div>

            <div
              className={cn('caption clickable text-center underline', showUsernamePassword && 'hidden')}
              onClick={() =>
                onSubmit({ email: 'bruker@utleggsappen.no', password: 'bruker' }, { setSubmitting: () => {} })
              }>
              Logg inn som bruker
            </div>

            <div
              className={cn('caption clickable text-center underline', showUsernamePassword && 'hidden')}
              onClick={() =>
                onSubmit({ email: 'admin@utleggsappen.no', password: 'admin' }, { setSubmitting: () => {} })
              }>
              Logg inn som administrator
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
